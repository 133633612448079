:root {
  --P-D: #525FE1;
  --P-L: #F86F03;
  --S-D: #FFA41B;
  --S-L: #FFF6F4;
}

.nav-bar {
  z-index: 11;
  position: fixed;
  margin-left: 0;
  margin-right: 0;
  width: calc(100% - 40px);
  display: grid;
  grid-template-columns: 85px 85px auto 85px 85px 85px;
  list-style-type: none;
  margin-top: -70px;
  padding: 0;
  overflow: hidden;
  background-color: var(--S-D);
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-bar.scrolled {
  width: 100%;
  border-radius: 0;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -90px;
  background-color: #525FE1;
}

@media (max-width: 482px) {
  .nav-bar {
    grid-template-columns: 1fr 1fr 0px 1fr 1fr 1fr;
  }

  .nav-link {
    font-size: small;
    padding: 10px;
  }
}

.nav-link {
  display: block;
  color: var(--S-L);
  font-weight: bold;
  text-align: center;
  padding: 14px 14px;
  text-decoration: none;
  transition: 0.3s;
}

.nav-link:hover {
  background-color: var(--S-L);
  color: var(--S-D);
}

.section-header {
  color: var(--S-L);
}

.blurb-grid {
  margin-top: 70px;
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 20px;
}

/* Home Page Styles */

.blurb-img {
  background-color: var(--P-L);
  border-radius: 5px;
  padding: 10px;
}

.canyon-pic {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.contact-logo {
  width: 100px;
  height: auto;
}

.blurb-content {
  align-self: center;
  text-align: center;
  color: var(--S-L);
}

.blurb-grid .blurb-img {
  order: 2;
}

.blurb-grid .blurb-content {
  order: 1;
}

@media (max-width: 800px) {
  .blurb-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {

  /* Change the width to whatever suits your needs */
  .blurb-grid {
    grid-template-columns: 1fr;
  }

  .blurb-grid .blurb-img {
    order: 2;
  }

  .blurb-grid .blurb-content {
    order: 1;
  }
}

@media (min-width: 601px) {
  .blurb-grid .blurb-img {
    order: 1;
  }

  .blurb-grid .blurb-content {
    order: 2;
  }
}

.ticker {
  margin-left: -20px;
  margin-right: -20px;
  width: 1fr;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.ticker__items {

  display: inline-block;
  animation: ticker 20s linear infinite;
}

@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}


.ticker-image {
  padding: 5px;
  background-color: var(--S-L);
  border-radius: 5px;
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-left: 40px;
}

.footer-placer {
  position: relative;
  min-height: 100vh;
}

.tech-list {
  padding: 10px;
  border-radius: 5px;

  color: var(--S-L);
}

.tech-list-item {
  background-color: var(--P-D);
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.tech-list-item h3 {
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--P-L);
}


/* School Page Styles */

.school-progress-grid {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
}

.course-container {
  margin: 20px;
  color: var(--S-L);
  text-align: center;
  background-color: var(--P-L);
  border-radius: 5px;
  animation: fadeIn 2s ease-in-out;
}

@media (max-width: 1400px) {
  .school-progress-grid {
    grid-template-columns: 1fr 1fr;
  }

  .more-information-container {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

@media (max-width: 900px) {
  .school-progress-grid {
    grid-template-columns: 1fr;
  }

  .more-information-container {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.course-container li {
  text-align: left;
  line-height: 2.5;
  animation: fadeIn 2s ease-in-out;
}

.more-information-container {
  margin: 20px;
  color: var(--S-L);
  text-align: center;
  background-color: var(--P-D);
  border-radius: 5px;
  animation: fadeIn 2s ease-in-out;
}

.top {
  text-align: center;
  color: var(--S-L);
  margin-top: 70px;
  animation: fadeIn 1s ease-in-out;
}

.note {
  text-align: center;
  color: var(--S-L);
  animation: fadeIn 3s ease-in-out;
}

.job-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content auto;
  gap: 20px;
  min-height: 100vh;
}

.job-position-container {
  color: var(--S-L);
  text-align: center;
  background-color: var(--P-L);
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  animation: fadeIn 1s ease-in-out;
  border-radius: 6px;
}

.project-card {
  display: grid;
  grid-template-columns: 220px 1fr;
  background-color: var(--P-L);
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  text-align: center;
  width: fit-content;
  padding: 10px;
  color: var(--S-L);
  text-decoration: none;
  transition: all 0.3s;
  margin-bottom: 50px;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-card img {
  width: 200px;
  height: auto;
}

.project-description {
  align-self: center;
}

.blog-posts-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr max-content 1fr;
}

.blog-post-thumbnail {
  width: 300px;
  background-color: var(--S-L);
  border: solid 3px var(--P-L);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  transition: 0.3s;
}

.blog-post-thumbnail:hover {
  transform: scale(1.02);
  border: solid 6px var(--P-D);
  cursor: pointer;
}

.blog-post-thumbnail img {
  width: 100%;
  height: auto;
}

.full-post {
  width: 700px;
  background-color: var(--S-L);
  color: black !important;
  font-weight: bold;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border: 10px solid var(--P-D);
  border-radius: 10px;
}

.post-head {
  text-align: center;
}

.full-post img {
  width: 300px;

}

.close-post-button {
  text-align: center;
  width: 100%;
  height: 40px;
  background-color: var(--P-L);
  color: var(--S-L);
  border: none;
  border-radius: 7px;
  font-weight: bold;
  transition: all 0.3s;
}

.close-post-button:hover {
  transform: scale(1.02);
  cursor: pointer;
}


.footer-container {
  background-color: var(--P-D);
  color: var(--S-L);
  margin: -20px;
  margin-top: -100px;
  width: calc(100% + 20px);
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-right {
  float: right;
}